<template>
  <div>
    <div slot="header" style="position: relative">
      <div class="query">
        <el-input
          style="margin-left: 0px"
          clearable
          :placeholder="showContents.numbering"
          v-model="queryData.numbering"
          @keyup.enter.native="getDataList(true)"
        ></el-input>
        <el-input
          style="width: 300px"
          clearable
          :placeholder="showContents.keyWord"
          v-model="queryData.keyWord"
          @keyup.enter.native="getDataList(true)"
        ></el-input>

        <el-date-picker
          style="width: 250px"
          v-model="startTimeAndEndTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="-"
          :start-placeholder="showContents.startDate"
          :end-placeholder="showContents.endDate"
          @change="getDataList(true)"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-select v-model="queryData.isPush" placeholder="推送状态">
              <el-option label="已推送" value="2"> </el-option>
              <el-option label="未推送" value="1"> </el-option>
            </el-select>
        <el-button type="success" class="ml10" @click="getDataList(true)">{{
          showContents.queryBtn
        }}</el-button>
        <!-- <el-button type="primary" @click="exportData1()">{{
          showContents.dcbgBtn
        }}</el-button> -->
        <el-button type="primary" @click="exportData2()">{{
          showContents.dcxzdbgBtn
        }}</el-button>
        <el-button type="primary" @click="exportData3()">{{
          showContents.dcxzdyhsjBtn
        }}</el-button>
        <el-button
          type="primary"
          v-if="isAuth('exportCsv')"
          @click="exportCsv()"
          >{{ showContents.dcCsvBtn }}</el-button
        >
        <el-upload
          v-if="isAuth('importCsvData')"
          style="margin-left: 10px"
          class="upload-demo"
          :action="`${
            $store.state.baseUrl
          }user-test-data/ImportCsvData/${getUserId()}`"
          :show-file-list="false"
          accept=".xls,.xlsx"
          :on-success="uploadSuccess"
          :on-error="$uploadError"
          :before-upload="$beforeUploadFile"
        >
          <el-button type="primary">{{ showContents.xjqdrCsvBtn }}</el-button>
        </el-upload>
        <el-button type="primary" @click="pushReport()">{{
          showContents.batchPushBtn
        }}</el-button>
      </div>
    </div>

    <el-table
      :data="listData"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      border
      stripe
      style="margin-top: 20px"
      ref="multipleTable"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        prop="numbering"
        :label="showContents.tableBarCode"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.numbering }}
          <el-button
            type="text"
            v-if="isAuth('修改条码')"
            @click="editNumberIng(scope.row.id)"
            >{{ showContents.tableBarCodeBtn }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        :label="showContents.tableUserName"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="sex"
        :label="showContents.tableSex"
        min-width="100"
      >
        <template slot-scope="scope">
          <el-tag>{{ $store.state.language == "en" ? "Female" : "女" }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="age"
        :label="showContents.tableAge"
        min-width="100"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.age }}</p>
          <!-- {{ showContents.tableAgeUnit }} -->
        </template>
      </el-table-column>
      <el-table-column
        prop="amh"
        :label="`amh${showContents.valueTxt}`"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="fsh"
        :label="`fsh${showContents.valueTxt}`"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="lh"
        :label="`lh${showContents.valueTxt}`"
        min-width="120"
        v-if="$store.state.language == 'zh'"
      ></el-table-column>
      <el-table-column
        prop="isPush"
        :label="showContents.pushText"
        min-width="120"
        v-if="$store.state.language == 'zh'"
      >
      <template slot-scope="scope">
          <p v-if="scope.row.isPush == 1">未推送</p>
          <p v-else-if="scope.row.isPush == 2">已推送</p>
          <p v-else>--</p>
        </template>
    </el-table-column>
      <!-- <el-table-column
        prop="push"
        :label="showContents.tableSfts"
        min-width="100"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.push ? 'success' : ''">{{
            scope.row.push ? "已推送" : "未推送"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="showContents.tableZljy" min-width="160">
        <template slot-scope="scope">
          <el-popover
            placement="bottom"
            title="诊疗建议"
            width="200"
            trigger="click"
            :content="scope.row.medicalAdvice"
            v-if="scope.row.medicalAdvice"
          >
            <el-button slot="reference" type="primary" size="mini">{{
              showContents.checkBtn
            }}</el-button>
          </el-popover>
          <p v-else>{{ showContents.tableZljyWlx }}</p>
        </template>
      </el-table-column>
      <el-table-column :label="showContents.tableGgjk" min-width="120">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="boneHealthPopup(scope.row.numbering)"
            size="mini"
            >{{ showContents.checkBtn }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="sex"
        :label="showContents.tableLanguage"
        min-width="100"
      >
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.lh === 0 &&
              scope.row.detectionTime
                ? 'success'
                : ''
            "
            >{{
              scope.row.lh === 0 &&
              scope.row.detectionTime
                ? showContents.tableLanguageTxt2
                : showContents.tableLanguageTxt1
            }}</el-tag
          >
        </template>
      </el-table-column> -->
      <el-table-column :label="showContents.detectionTime" min-width="160">
        <template slot-scope="scope">
          <p v-if="scope.row.detectionTime">
            {{ scope.row.detectionTime | dateFormat1 }}
          </p>
          <el-tag v-else>{{ showContents.detectionTimeTxt }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="showContents.createTime" min-width="160">
        <template slot-scope="scope">
          <p>{{ scope.row.createTime | dateFormat1 }}</p>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        prop="operation"
        :label="showContents.operation"
        width="320px;"
      >
        <template slot-scope="scope">
          <div class="tableOperation">
            <el-button type="text" @click="showHistory(scope.row)">{{
              showContents.historyBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button type="text" @click="showTalk(scope.row)">{{
              showContents.talkBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button type="text" @click="pushReport(scope.row)">{{
              showContents.pushBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button type="text" @click="openUrl(scope.row)">{{
              showContents.checkBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button type="text" @click="downloadReport(scope.row)">{{
              showContents.downloadReportBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button class="F56C6C" type="text" @click="del(scope.row.id)">{{
              showContents.deleteBtn
            }}</el-button>
            <span class="operationLine" v-if="isPushAuth">|</span>
            <el-dropdown v-if="isPushAuth">
              <span class="el-dropdown-link">
                <el-button type="text">
                  {{ showContents.pushOperation
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="
                    (isPushAuth && getQueryId() == '1427916520868024321') ||
                    (isPushAuth && getQueryId() == '1402455457745866753')
                  "
                >
                  <el-button
                    type="text"
                    @click="pushReportQuFu(scope.row.id)"
                    >{{ showContents.tsdqfyy }}</el-button
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <el-pagination
      class="floatRight"
      v-if="total > 10"
      @size-change="getDataListSizeChange"
      @current-change="getDataListCurrentChange"
      :current-page.sync="queryData.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="queryData.size"
      :total="total"
      layout="total,sizes, prev, pager, next"
    ></el-pagination>
    <bone-health-vue ref="boneHealthVue"></bone-health-vue>
    <!-- 话术列表 -->
    <showTalk ref="showTalkRef" ></showTalk>
    <!-- 历史对比列表 -->
    <showHistory ref="showHistoryRef" ></showHistory>
  </div>
</template>

<script>
import boneHealthVue from "./boneHealth.vue";
import showTalk  from "./showTalk";
import showHistory  from "./showHistory";
export default {
  components: {
    boneHealthVue,
    showTalk,
    showHistory
  },
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
        sex: "女",
        userLoginId: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      startTimeAndEndTime: [],
      selectionList: [],
      isPushAuth: false,

      showContents: {
        keyWord: "请输入昵称/手机号/邮箱",
        numbering: "条码号",
        startDate: "开始日期",
        endDate: "结束日期",
        queryBtn: "查询",
        dcbgBtn: "导出报告",
        dcxzdbgBtn: "导出选中的报告",
        dcxzdyhsjBtn: "导出选中的用户数据",
        dcCsvBtn: "导出Csv",
        xjqdrCsvBtn: "新机器导入Excel",
        tableBarCode: "条码号",
        tableBarCodeBtn: "修改",
        tableUserName: "姓名",
        tableSex: "性别",
        tableAge: "年龄",
        tableAgeUnit: "岁",
        valueTxt: "值",
        tableSfts: "是否推送",
        tableZljy: "诊疗建议",
        tableZljyWlx: "未填写",
        tableGgjk: "骨骼健康",
        tableLanguage: "语言",
        tableLanguageTxt1: "中文",
        tableLanguageTxt2: "英文",
        detectionTime: "报告时间",
        detectionTimeTxt: "待检测",
        createTime: "创建时间",
        operation: "操作",

        checkBtn: "查看",
        downloadReportBtn: "下载报告",
        deleteBtn: "删除",
        pushOperation: "推送操作",
        tsdqfyy: "推送到曲阜医院",
        pushBtn: "推送",
        pushText: "推送状态",
        batchPushBtn: "推送选中的报告",
        talkBtn: '话术',
        historyBtn: '历史对比'
      },
      czsbqcshlxgly:
        this.$store.state.language == "zh"
          ? "操作失败，请重试或联系管理员"
          : "Operation failed, please try again or contact the administrator",
    };
  },
  created() {},
  mounted() {
    // 1402455457745866753 测试账户 13007250123 1234567
    // 1427916520868024321 曲阜中医院 13007250123 1234567
    if (
      this.isAuth("推送操作") ||
      this.getQueryId() == "1427916520868024321" ||
      this.getQueryId() == "1402455457745866753"
    ) {
      this.isPushAuth = true;
    }
  },
  methods: {
    init() {
      if (this.getUserRoleName() == "客户") {
        this.$set(this.queryData, "userLoginId", this.getQueryId());
      }
      this.getDataList();
      if (this.$store.state.language == "en") {
        this.showContents = {
          keyWord: "Please enter your username/phone number/email",
          numbering: "Barcode",
          startDate: "Start date",
          endDate: "End date",
          dcbgBtn: "Export report",
          dcxzdbgBtn: "Export the selected report",
          dcxzdyhsjBtn: "Export selected user data",
          dcCsvBtn: "Export Csv",
          xjqdrCsvBtn: "Test Data Imput ",
          queryBtn: "query",
          tableBarCode: "Barcode",
          tableBarCodeBtn: "edit",
          tableUserName: "Username",
          tableSex: "Gender",
          tableAge: "age",
          tableAgeUnit: "year",
          valueTxt: " Level",
          tableSfts: "Whether to push",
          tableZljy: "Diagnosis and treatment recommendations",
          tableZljyWlx: "Not filled in",
          tableGgjk: "Skeletal health",
          tableLanguage: "tableLanguage",
          tableLanguageTxt1: "Chinese",
          tableLanguageTxt2: "English",
          detectionTime: "Report time",
          detectionTimeTxt: "To be tested",
          detectionTime: "Report time",
          createTime: "Creation time",
          operation: "operation",
          checkBtn: "Report",
          downloadReportBtn: "downloadReport",
          deleteBtn: "Delete",
          pushOperation: "push operation",
          tsdqfyy: "Push to Qufu Hospital",
        };
      }
    },
    boneHealthPopup(numbering) {
      this.$nextTick(() => {
        this.$refs.boneHealthVue.init(numbering);
      });
    },
    uploadSuccess(res) {
      this.$store.state.beforeUploadLoading.close();
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "导入成功!",
        });
        this.getDataList(true);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    editNumberIng(id) {
      this.$prompt("请输入新条码", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$http
            .put(`user-test-data/updateNumberingById`, {
              id,
              numbering: value,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.getDataList();
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消修改",
          });
        });
    },
    pushReportQuFu(id) {
      if (id) {
        this.$confirm(`是否推送数据？`, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const loadLoading = this.$loading({
              lock: true,
              text: "推送中...",
              spinner: "el-icon-loading",
            });
            this.$http
              .post(`user-test-data/pushReportQuFu/${id}`)
              .then((res) => {
                loadLoading.close();
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message: "推送成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    updateMenopausalAge(id, menopausalAge) {
      if (id) {
        this.$confirm(`是否修改所选择的数据？`, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http
              .put(`user-test-data/updateById`, {
                id: id,
                menopausalAge: menopausalAge ? false : true,
              })
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    handleSelectionChange(e) {
      this.selectionList = e;
    },
    /**
     * 推送报告
     */
     pushReport(row) {
      let idList = []
      if (row?.id) {
        idList.push(row.id)
      }else {
        if (this.selectionList?.length === 0) {
          this.$message({
            message: "请选择需要推送的数据",
            type: "warning",
          });
          return
        }
        idList = this.selectionList.map(item => item.id)
      }
      this.$confirm("是否推送所选择的数据?", "温馨提示",
          {
            confirmButtonText:
              this.$store.state.language == "en" ? "confirm" : "确定",
            cancelButtonText:
              this.$store.state.language == "en" ? "cancel" : "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$http
              .post(`user-test-data/pushUserTestData`, {idList: idList, customerId: this.$store.state.loginUserInfo.id})
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message:"推送成功",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message:
                this.$store.state.language == "en"
                  ? "Cancel operation"
                  : "取消操作",
            });
          });
    },
    /**
     * 查看话术
     */
     showTalk(row) {
      this.$nextTick(() => {
          this.$refs.showTalkRef.init(row.id);
        });
    },
    /**
     * 查看历史对比数据
     */
     showHistory(row) {
      this.$nextTick(() => {
          this.$refs.showHistoryRef.init({userId: row.userId, sex: '女'});
        });
    },
    downloadReport(row) {
      let url = "";

      if (location.host.indexOf("admin.predicthealthen.com") !== -1)
        url = `https://api.predicthealth.cn/user-test-data/exportPdfById/${row.id}?version=EN`;
      else {
        url = `https://api.predicthealth.cn/user-test-data/exportPdfById/${row.id}`;
      }

      window.open(`${url}`);
    },
    openUrl(row) {
      let url = "";
      // if (row.lh === 0) url = `https://en.predicthealth.cn`;
      // else {
      //   url = `https://360.predicthealth.cn`;
      // }
      if (location.host.indexOf("admin.predicthealthen.com") !== -1) {
        url = `https://en.predicthealth.cn`;
      } else {
        url = `https://360.predicthealth.cn`;
      }
      window.open(`${url}/WomenHealth?id=${row.id}`);
    },
    exportData3() {
      if (this.selectionList.length > 0) {
        const downloadLoading = this.$loading({
          lock: true,
          text: "正在导出，数据量过大时请耐心等待......",
          spinner: "el-icon-loading",
        });
        let list = this.selectionList;
        let ids = [];
        list.forEach((item) => {
          ids.push(item.id);
        });
        this.$http
          .post(`/user-test-data/exportUserData`, ids, {
            responseType: "blob",
          })
          .then((res) => {
            if (res.data != null) {
              // 处理响应正文responseText，多数是json数据
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });

              let url = window.URL.createObjectURL(blob);
              const link = document.createElement("a"); // 创建a标签
              link.href = url;
              link.download =
                this.getTodayTime2(new Date()) + "用户数据" + ".xlsx"; // 重命名文件
              link.click();
              URL.revokeObjectURL(url); // 释放内存
            } else
              this.$message({
                message: this.czsbqcshlxgly,
                type: "warning",
              });
            downloadLoading.close();
          })
          .catch((err) => {
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
            downloadLoading.close();
          });
      } else
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
        });
    },
    exportData2() {
      if (this.selectionList.length > 0) {
        const downloadLoading = this.$loading({
          lock: true,
          text: "正在导出，数据量过大时请耐心等待......",
          spinner: "el-icon-loading",
        });
        let list = this.selectionList;
        let ids = [];
        list.forEach((item) => {
          ids.push(item.id);
        });
        this.$http
          .post(`/user-test-data/exportPdfByIds`, ids, {
            responseType: "blob",
          })
          .then((res) => {
            if (res.data != null) {
              // 处理响应正文responseText，多数是json数据
              let blob = new Blob([res.data], {
                type: "application/zip",
              });

              let url = window.URL.createObjectURL(blob);
              const link = document.createElement("a"); // 创建a标签
              link.href = url;
              link.download =
                this.getTodayTime2(new Date()) + "女性检测数据" + ".zip"; // 重命名文件
              link.click();
              URL.revokeObjectURL(url); // 释放内存
            } else
              this.$message({
                message: this.czsbqcshlxgly,
                type: "warning",
              });
            downloadLoading.close();
          })
          .catch((err) => {
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
            downloadLoading.close();
          });
      } else
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
        });
    },
    exportData1() {
      const downloadLoading = this.$loading({
        lock: true,
        text: "正在导出，数据量过大时请耐心等待......",
        spinner: "el-icon-loading",
      });
      let data = this.queryData;
      if (this.startTimeAndEndTime && this.startTimeAndEndTime.length > 0) {
        let timeArr = this.startTimeAndEndTime;
        data.startTime = timeArr[0];
        data.endTime = timeArr[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      this.$http
        .get(
          `user-test-data/exportPdf?startTime=${encodeURIComponent(
            data.startTime
          )}&endTime=${encodeURIComponent(
            data.endTime
          )}&sex=${encodeURIComponent(data.sex)}&userLoginId=${
            data.userLoginId
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.data != null) {
            // 处理响应正文responseText，多数是json数据
            let blob = new Blob([res.data], {
              type: "application/zip",
            });

            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download =
              this.getTodayTime2(new Date()) + "女性检测数据" + ".zip"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url); // 释放内存
          } else
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
          downloadLoading.close();
        })
        .catch((err) => {
          this.$message({
            message: this.czsbqcshlxgly,
            type: "warning",
          });
          downloadLoading.close();
        });
    },
    exportCsv() {
      const downloadLoading = this.$loading({
        lock: true,
        text: "正在导出，数据量过大时请耐心等待......",
        spinner: "el-icon-loading",
      });
      let data = this.queryData;
      if (this.startTimeAndEndTime && this.startTimeAndEndTime.length > 0) {
        let timeArr = this.startTimeAndEndTime;
        data.startTime = timeArr[0];
        data.endTime = timeArr[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      this.$http
        .get(
          `user-test-data/exportCsv?startTime=${encodeURIComponent(
            data.startTime
          )}&endTime=${encodeURIComponent(
            data.endTime
          )}&sex=${encodeURIComponent(data.sex)}&userLoginId=${
            data.userLoginId
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.data != null) {
            // 处理响应正文responseText，多数是json数据
            let blob = new Blob([res.data], {
              type: "application/csv",
            });

            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download =
              this.getTodayTime2(new Date()) + "女性检测数据" + ".csv"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url); // 释放内存
          } else
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
          downloadLoading.close();
        })
        .catch((err) => {
          this.$message({
            message: this.czsbqcshlxgly,
            type: "warning",
          });
          downloadLoading.close();
        });
    },
    /**
     * 删除数据
     */
    del(id) {
      if (id) {
        this.$confirm(
          this.$store.state.language == "en"
            ? "Do you want to delete the selected data？"
            : "是否删除所选择的数据?",

          this.$store.state.language == "en" ? "Kind reminder" : "温馨提示",
          {
            confirmButtonText:
              this.$store.state.language == "en" ? "confirm" : "确定",
            cancelButtonText:
              this.$store.state.language == "en" ? "cancel" : "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$http
              .delete(`user-test-data/baseDeleteById/${id}`)
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message:
                      this.$store.state.language == "en"
                        ? "Delete successful"
                        : "删除成功",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message:
                this.$store.state.language == "en"
                  ? "Cancel operation"
                  : "取消操作",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      let data = this.queryData;
      if (this.startTimeAndEndTime && this.startTimeAndEndTime.length > 0) {
        let timeArr = this.startTimeAndEndTime;
        data.startTime = timeArr[0];
        data.endTime = timeArr[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      if (data.keyWord) data.keyWord = data.keyWord.trim();
      this.$http
        .get(`user-test-data/getSelectPage`, {
          params: data,
        })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            }
            this.loading = false;
          }
        });
    },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped></style>
