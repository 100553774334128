import { render, staticRenderFns } from "./woman.vue?vue&type=template&id=1ccb1833&scoped=true"
import script from "./woman.vue?vue&type=script&lang=js"
export * from "./woman.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ccb1833",
  null
  
)

export default component.exports