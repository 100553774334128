
<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="showContents.manLabel"
            name="man"
            v-if="isAuth('reportList:man')"
          >
            <man ref="man"></man>
          </el-tab-pane>
          <el-tab-pane :label="showContents.womanLabel" name="woman">
            <woman ref="woman"></woman>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
import man from "./reportTemplate/man.vue";
import woman from "./reportTemplate/woman.vue";
export default {
  components: {
    man,
    woman,
  },
  data() {
    return {
      activeName: "man",
      showContents: {
        manLabel:
          this.$store.state.language == "zh" ? "男性报告" : "Male report",
        womanLabel:
          this.$store.state.language == "zh" ? "女性报告" : "Female Report",
      },
    };
  },
  created() {
    if (this.isAuth("reportList:man")) {
      this.$nextTick(() => {
        this.$refs.man.init();
      });
    } else {
      this.activeName = "woman";
      this.$nextTick(() => {
        this.$refs.woman.init();
      });
    }
  },
  methods: {
    handleClick(e) {
      let name = e.name;
      if (name == "man")
        this.$nextTick(() => {
          this.$refs.man.init();
        });
      else if (name == "woman")
        this.$nextTick(() => {
          this.$refs.woman.init();
        });
    },
  },
};
</script>
