<template>
  <el-dialog
    title="历史对比"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="1000px"
  >
    <div>
      <!-- 添加折线图容器 -->
      <div id="lineChart" style="width: 100%; height: 400px;"></div>
      <el-tabs v-model="activeName" class="demo-tabs" type="card">
        <el-tab-pane :label="item.detectionName" :name="item.detectionName" v-for="item in listData" :key="item.detectionName"></el-tab-pane>
      </el-tabs>
      <el-table
      :data="tabData[activeName] || []"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      border
      stripe
      style="margin-top: 20px"
    >
      <el-table-column
        prop="detectionName"
        :label="showContents.detectionName"
        min-width="160"
      ></el-table-column>

      <!-- <el-table-column prop="identifier" label="识别码" min-width="120"></el-table-column> -->
      <el-table-column
        prop="detectionTime"
        :label="showContents.detectionTime"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="detectionValue"
        :label="showContents.detectionValue"
        min-width="120"
      ></el-table-column>
    </el-table>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible=false">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      listData: [],
      tabData: [],
      activeName: '',
      visible: false,
      loading: false,
      showContents: {
        detectionName: "检测项目",
        detectionTime: "检测时间",
        detectionValue: "检测值"
      },
    };
  },
  methods: {
    init(data) {
      this.getDataList(data);
      this.listData = []
      this.tabData = {}
      this.visible = true;
    },
    /**
     * 加载列表数据数据
     */
    getDataList(data) {
      let loginUserInfo = this.$store.state.loginUserInfo;
      const resData = {
          userId: data.userId,
          sex: data.sex,
          customerId: loginUserInfo.isAdmin ? 'ALL' : (loginUserInfo.userId || null),
        }
        this.loading = true
      this.$http
        .post(
          `user-test-data/findUserHistoryData`, resData)
        .then((res) => {
          if (res.data.code === 200) {
            this.listData = res.data.data || [];
            const tabData = {}
            this.listData .forEach(item => {
              item.detectionData.forEach(d => {
                if(tabData[item.detectionName]) {
                tabData[item.detectionName].push({
                detectionName: item.detectionName,
                detectionTime: d.detectionTime,
                detectionValue: d.detectionValue
              })
              }else {
                tabData[item.detectionName] = [{
                  detectionName: item.detectionName,
                  detectionTime: d.detectionTime,
                  detectionValue: d.detectionValue
                }]
              }
              })
              // 对每个检测项目的数据进行时间倒序排序
              tabData[item.detectionName].sort((a, b) => 
                new Date(b.detectionTime) - new Date(a.detectionTime)
              )
            })
            this.activeName = this.listData[0]?.detectionName
            this.tabData = tabData
            this.loading = false
            this.$nextTick(() => {
              this.initChart()
            })
          }
        });
    },

    initChart() {
      if (this.chartInstance) {
        this.chartInstance.dispose()
      }
      
      this.chartInstance = echarts.init(document.getElementById('lineChart'))
      
      // 处理数据
      const series = this.listData.map(item => ({
        name: item.detectionName,
        type: 'line',
        smooth: true,
        data: item.detectionData.map(d => d.detectionValue),
        // 为每条线添加标记点
        markPoint: {
          data: [
            { type: 'max', name: '最大值' },
            { type: 'min', name: '最小值' }
          ]
        }
      }))

      // 获取所有检测时间
      const times = this.listData[0]?.detectionData.map(d => 
        this.formatDate(d.detectionTime)
      ) || []
      
      // 配置项
      const option = {
        title: {
          text: '检测数据趋势图'
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(params) {
            let result = params[0].axisValue + '<br/>';
            params.forEach(item => {
              result += item.seriesName + ': ' + item.value + '<br/>';
            });
            return result;
          }
        },
        legend: {
          data: this.listData.map(item => item.detectionName)
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: times,
          axisLabel: {
            rotate: 45 // 斜着显示日期
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          }
        },
        series: series
      }
      
      this.chartInstance.setOption(option)
    },

    // 格式化日期
    formatDate(dateStr) {
      return dateStr.split(' ')[0] // 只显示日期部分
    },
  },
};
</script>

<style lang="less" scoped>
.talk-title {
  color: #CF696C;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.talk-content {
  padding: 0 20px;
  margin-bottom: 20px;
}
</style>
